import React, { useEffect, useState } from 'react';
import Stats from './Stats';
import TotalCosts from './TotalCosts';
import VehicleStats from './VehicleStats';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { baseURL, endpoints } from '../endpoints';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

const MostFrequentWorkshops = () => {
  const [data, setData] = useState([]);

  useQuery('getMostFrequentWorkshops', () =>
    fetch('http://18.141.138.26:5555/invoices/most_visited_workshops', {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then(({ data }) =>
        setData(
          data.map((d: { workshop_id: number }) => ({
            ...d,
            id: d.workshop_id,
          }))
        )
      )
  );

  return (
    <Box>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Most Frequent Workshops
        </Typography>
        <DataGridPro
          columns={[
            {
              field: 'Workshop',
              headerName: 'Workshop',
              flex: 0.5,
              renderCell: (params: GridRenderCellParams<any>) => (
                <>{params.value.name}</>
              ),
            },
            {
              field: 'amount',
              headerName: 'Amount',
              flex: 0.5,
            },
          ]}
          rows={data}
          autoHeight
        />
      </Paper>
    </Box>
  );
};

const MostExpensiveVehicles = () => {
  const [data, setData] = useState([]);

  useQuery('getVehiclesWithHighExpense', () =>
    fetch('https://maintenancebk.pvcarrental.com/api/widget/high_expense', {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then(({ entries }) =>
        setData(
          entries.map((d: { license_plate: number; amount: number }) => ({
            ...d,
            id: d.license_plate,
          }))
        )
      )
  );

  const [sortModel, _] = useState<GridSortModel>([
    {field: 'amount', sort: 'desc'}
  ]);

  return (
    <Box>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          High-expense Vehicles
        </Typography>
        <DataGridPro
          sortModel={sortModel}
          columns={[
            {
              field: 'license_plate',
              headerName: 'License Plate',
              flex: 0.5,
              renderCell: (params: GridRenderCellParams<string>) => (
                <Link
                  component={RouterLink}
                  to={`/vehicle/${params.value}`}
                  underline="none"
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'amount',
              headerName: 'Amount',
              flex: 0.5,
              type: 'number',
              renderCell: (params: GridRenderCellParams<number>) => (
                <>{formatter.format(params.value)} MMK</>
              ),
            },
          ]}
          rows={data}
          autoHeight
        />
      </Paper>
    </Box>
  );
};

const Dashboard = (props: Titleable) => {
  useEffect(() => props.setTitle('Dashboard'), []);

  return (
    <Box padding={2}>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={2}>
          <VehicleStats />
        </Grid>
        <Grid item xs={2}>
          <Stats
            endpoint={new URL(endpoints.get('carPartCount')!!, baseURL).href}
            title="Car Parts"
            listRoute={endpoints.get('carPartListRoute')!!}
          />
        </Grid>
        <Grid item xs={2}>
          <Stats
            endpoint={new URL(endpoints.get('operationCount')!!, baseURL).href}
            title="Operations"
            listRoute={endpoints.get('operationListRoute')!!}
          />
        </Grid>
        <Grid item xs={2}>
          <Stats
            endpoint={new URL(endpoints.get('categoryCount')!!, baseURL).href}
            title="Categories"
            listRoute={endpoints.get('categoryListRoute')!!}
          />
        </Grid>
        <Grid item xs={2}>
          <Stats
            endpoint={new URL(endpoints.get('invoiceCount')!!, baseURL).href}
            title="Invoices"
            listRoute={endpoints.get('invoiceListRoute')!!}
          />
        </Grid>
        <Grid item xs={2}>
          <Stats
            endpoint={new URL(endpoints.get('workshopCount')!!, baseURL).href}
            title="Workshops"
            listRoute={endpoints.get('workshopListRoute')!!}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={6}>
          <TotalCosts />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={6}>
          <MostExpensiveVehicles />
        </Grid>
        <Grid item xs={6}>
          <MostFrequentWorkshops />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
