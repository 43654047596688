import { Box, IconButton, Paper } from '@mui/material';
import { BuildOutlined, Refresh } from '@mui/icons-material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'ramda';
import { endpoints, baseURL } from '../../endpoints';
import { Link } from 'react-router-dom';

type VehicleList = Array<string>;

const VehicleStats = () => {
  const [vehicles, setVehicles] = useState<VehicleList>([]);
  const endpoint = new URL(endpoints.get('vehicleCount')!!, baseURL).href;

  const getVehicles = () => {
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setVehicles(data));
  };

  const { isLoading, isFetching } = useQuery('getVehicles', getVehicles);

  const refresh = () => {
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setVehicles(data));
  };

  return (
    <Paper
      sx={{
        p: 2,
        textAlign: 'right',
      }}
    >
      <Box
        sx={{
          fontSize: 36,
          fontWeight: 'medium',
        }}
      >
        {isFetching || isLoading || isEmpty(vehicles) ? '--' : vehicles.length}
      </Box>
      <Box>Vehicles</Box>
      <Box sx={{ marginTop: 1 }}>
        <IconButton size="small" onClick={() => refresh()}>
          <Refresh />
        </IconButton>
        <IconButton
          size="small"
          component={Link}
          to={endpoints.get('vehicleListRoute')!!}
        >
          <BuildOutlined />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default VehicleStats;
