import CategoryDrawer from './CategoryDrawer';
import CategoryTable from './CategoryTable';
import React, { useEffect, useState } from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { Box, Grid, SpeedDial, SpeedDialAction } from '@mui/material';
import { CategoryPageProvider } from './context';
import { FiberNewOutlined } from '@mui/icons-material';

const CategoryPageContent = (props: Titleable) => {
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => props.setTitle('Categories'), []);

  const handleOpen = () => setSpeedDialOpen(true);
  const handleClose = () => setSpeedDialOpen(false);

  const actions = [
    { icon: <FiberNewOutlined />, name: 'New', fun: () => setOpen(true) },
  ];

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial for Categories"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        open={speedDialOpen}
        icon={<SpeedDialIcon />}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.fun}
          />
        ))}
      </SpeedDial>
      <Grid container padding={2}>
        <CategoryTable />
      </Grid>
      <CategoryDrawer
        drawerOpen={open}
        setDrawerOpen={(drawerState) => setOpen(drawerState)}
        setSpeedDialOpen={(speedDialState) => setSpeedDialOpen(speedDialState)}
      />
    </Box>
  );
};

const CategoryPage = (props: Titleable) => (
  <CategoryPageProvider>
    <CategoryPageContent {...props} />
  </CategoryPageProvider>
);

export default CategoryPage;
