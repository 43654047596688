import {
  Box,
  Button,
  Drawer,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, memo } from 'react';
import { useCarPartPage } from './context';

const NameInput = () => {
  const { state, dispatch } = useCarPartPage();
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'name', payload: e.target.value });

  return (
    <TextField
      error={state.hasError}
      fullWidth
      helperText={state.hasError ? state.errors[0] : ''}
      label="Name"
      onChange={handleChange}
      required
      value={state.name}
    />
  );
};

const CarPartDrawer = () => {
  const { state, dispatch } = useCarPartPage();

  const saveCarPart = async () => {
    dispatch({ type: 'submit', payload: state.name });
  };

  const cancel = async () => {
    dispatch({ type: 'drawerOpen', payload: false });
    dispatch({ type: 'speedDialOpen', payload: false });
  };

  return (
    <Drawer
      anchor="right"
      open={state.drawerOpen}
      onClose={cancel}
      style={{ width: '100%', zIndex: 9999 }}
    >
      <Box role="presentation" minWidth="25vw">
        <Box component="form" autoComplete="off" noValidate sx={{ p: 2 }}>
          <Typography variant="h6">Create New Car Part</Typography>
          <FormControl fullWidth sx={{ mx: 1, mt: '1em' }} variant="standard">
            <NameInput />
          </FormControl>
          <FormControl
            sx={{ mt: '2em', display: 'flex', flexDirection: 'row-reverse' }}
            variant="standard"
          >
            <Box>
              <Button variant="contained" onClick={() => saveCarPart()}>
                Save
              </Button>
            </Box>
            <Box sx={{ mr: 1 }}>
              <Button onClick={cancel}>Cancel</Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </Drawer>
  );
};

const CarPartDrawerWithMemo = memo(CarPartDrawer);

export default CarPartDrawerWithMemo;
