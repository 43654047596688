import React, { useState } from 'react';
import {
  Menu as MenuIcon,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  DashboardOutlined,
  DirectionsCar,
  Inventory2Outlined,
  ConstructionOutlined,
  CategoryOutlined,
  ReceiptLongOutlined,
  HomeRepairServiceOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Box,
  CSSObject,
  IconButton,
  styled,
  Theme,
  Toolbar,
  Typography,
  useTheme,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  CssBaseline,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
} from '@mui/material';
import { Link, Route, Routes } from 'react-router-dom';
import { endpoints } from './endpoints';
import Analysis from './Analysis';
import CarPartPage from './CarPartPage';
import CategoryPage from './CategoryPage';
import Dashboard from './Dashboard';
import InvoicePage from './InvoicePage';
import OperationPage from './OperationPage';
import VehicleListPage from './VehicleListPage';
import WorkshopPage from './WorkshopPage';
import VehiclePage from './VehiclePage';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import InvoiceForm from './InvoiceForm';
import SingleInvoicePage from './SingleInvoicePage';
import { createTheme } from '@mui/material/styles';

LicenseInfo.setLicenseKey(
  'd78f450f75a9c7144d8e44b9527c760bT1JERVI6MzM4MzIsRVhQSVJZPTE2NzAyNDIyMzIwMDAsS0VZVkVSU0lPTj0x'
);

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7.5)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#169a5a',
      },
    }
  });

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Maintenance');

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  return (
    <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightOutlined />
              ) : (
                <ChevronLeftOutlined />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem button key={0} component={Link} to="/">
              <ListItemIcon>
                <DashboardOutlined />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              key={1}
              component={Link}
              to={endpoints.get('vehicleListRoute')!!}
            >
              <ListItemIcon>
                <DirectionsCar />
              </ListItemIcon>
              <ListItemText primary="Vehicles" />
            </ListItem>
            <ListItem
              button
              key={2}
              component={Link}
              to={endpoints.get('carPartListRoute')!!}
            >
              <ListItemIcon>
                <Inventory2Outlined />
              </ListItemIcon>
              <ListItemText primary="Car Parts" />
            </ListItem>
            <ListItem
              button
              key={3}
              component={Link}
              to={endpoints.get('operationListRoute')!!}
            >
              <ListItemIcon>
                <ConstructionOutlined />
              </ListItemIcon>
              <ListItemText primary="Operations" />
            </ListItem>
            <ListItem
              button
              key={4}
              component={Link}
              to={endpoints.get('categoryListRoute')!!}
            >
              <ListItemIcon>
                <CategoryOutlined />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItem>
            <ListItem
              button
              key={5}
              component={Link}
              to={endpoints.get('invoiceListRoute')!!}
            >
              <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon>
              <ListItemText primary="Invoices" />
            </ListItem>
            <ListItem
              button
              key={6}
              component={Link}
              to={endpoints.get('workshopListRoute')!!}
            >
              <ListItemIcon>
                <HomeRepairServiceOutlined />
              </ListItemIcon>
              <ListItemText primary="Workshops" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key={0}>
              <ListItemIcon>
                <InfoOutlined />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader />
          <Routes>
            <Route
              path={endpoints.get('vehicleListRoute')}
              element={<VehicleListPage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('carPartListRoute')}
              element={<CarPartPage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('operationListRoute')}
              element={<OperationPage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('categoryListRoute')}
              element={<CategoryPage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('invoiceListRoute')}
              element={<InvoicePage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('workshopListRoute')}
              element={<WorkshopPage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('vehicleRoute')}
              element={<VehiclePage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('invoiceFormRoute')}
              element={<InvoiceForm setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('singleInvoiceRoute')}
              element={<SingleInvoicePage setTitle={setTitle} />}
            />
            <Route
              path={endpoints.get('analysisRoute')}
              element={<Analysis setTitle={setTitle} />}
            />
            <Route path="/" element={<Dashboard setTitle={setTitle} />} />
          </Routes>
        </Box>
      </Box>
    </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
