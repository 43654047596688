import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { baseURL, endpoints } from '../endpoints';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteValue,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useQuery } from 'react-query';
import { isNil } from 'ramda';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';

type SelectedOperation = Operation & { categories: Array<Category> };

type SelectedCategory = Category;

const mb: number = 3;
const mr: number = 3;

const getEndpoint = (key: string): string =>
  new URL(endpoints.get(key)!!, baseURL).href;

const ItemSelection = (props: ItemSelectionProps) => {
  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] =
    useState<SelectedOperation>();
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>();
  const [quantity, setQuantity] = useState<number>(1);

  const operationEndpoint = getEndpoint('operationList');

  const getOperations = () =>
    fetch(operationEndpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setOperations(data));

  useQuery('getOperations', getOperations);

  const handleChange = (
    se: SyntheticEvent,
    av: AutocompleteValue<any, any, any, any>,
    acr: AutocompleteChangeReason
  ) => {
    console.group('operation');
    console.group('autocomplete value');
    console.log(av);
    console.groupEnd();
    console.groupEnd();

    setSelectedOperation(av);
    setSelectedCategory(av?.categories[0] ?? []);
  };

  const handleInputChange = (
    se: SyntheticEvent,
    s: string,
    aicr: AutocompleteInputChangeReason
  ) => {
    console.group('operation');
    console.group('string');
    console.log(s);
    console.groupEnd();
    console.groupEnd();
  };

  const handleCategoryChange = (se: SyntheticEvent, s: string) => {
    console.group('category change');
    console.log(s);
    console.groupEnd();

    const id = parseInt(s);

    setSelectedCategory(
      selectedOperation?.categories.filter((c: Category) => c.id === id)[0]
    );
  };

  const addToSelection = () => {
    console.group('this selection will be added');
    console.log(selectedOperation);
    console.log(selectedCategory);
    console.log(quantity);
    console.groupEnd();

    if (!selectedOperation || !selectedCategory) return;

    const newSelectedItem: SelectedItem = {
      id: generateUniqueID(),
      operation: selectedOperation,
      category: selectedCategory,
      quantity,
    };

    props.setSelectedItems([...props.selectedItems, newSelectedItem]);
  };

  const handleQuantityChange = (
    ce: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setQuantity(parseInt(ce.target.value));

  return (
    <Grid container direction="row" alignItems="center">
      <Box sx={{ mb, mr }}>
        <Autocomplete
          getOptionLabel={(option: Operation) => option.name}
          onChange={(se, av, acr) => handleChange(se, av, acr)}
          onInputChange={(se, s, aicr) => handleInputChange(se, s, aicr)}
          disablePortal
          options={operations}
          renderInput={(params) => (
            <TextField
              {...params}
              key={params.id}
              label="Operation"
              sx={{ width: 300 }}
            />
          )}
        />
      </Box>
      <Box sx={{ mb, mr, width: 300 }}>
        {selectedOperation && (
          <RadioGroup
            defaultValue={selectedOperation.categories[0].id}
            value={selectedCategory?.id}
            onChange={(se, s) => handleCategoryChange(se, s)}
          >
            {selectedOperation?.categories.map((c: Category) => (
              <FormControlLabel
                key={c.id}
                value={c.id}
                control={<Radio />}
                label={c.name}
              />
            ))}
          </RadioGroup>
        )}
      </Box>
      <Box sx={{ mb, mr }}>
        <TextField
          type="number"
          label="Quantity"
          defaultValue={quantity}
          onChange={handleQuantityChange}
        />
      </Box>
      <Box sx={{ mb }}>
        <Button
          variant="contained"
          onClick={addToSelection}
          disabled={
            isNil(selectedCategory) ||
            isNil(selectedOperation) ||
            isNil(quantity)
          }
        >
          Add
        </Button>
      </Box>
    </Grid>
  );
};

export default ItemSelection;
