import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  Stack,
} from '@mui/material';
import { FiberNewOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import OperationDrawerWithMemo from './OperationDrawer';
import OperationTableWithMemo from './OperationTable';
import { OperationPageProvider, useOperationPage } from './context';
import { baseURL, endpoints } from '../endpoints';

const endpoint = new URL(endpoints.get('operationList')!!, baseURL).href;

const OperationPageContent = (props: Titleable) => {
  const { state, dispatch } = useOperationPage();
  const [operations, setOperations] = useState<Array<Operation>>([]);

  const getOperations = () =>
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setOperations(data));

  useQuery('getOperations', getOperations);

  useEffect(() => props.setTitle('Operations'), []);

  const handleOpen = async () =>
    dispatch({ type: 'speedDialOpen', payload: true });
  const handleClose = async () =>
    dispatch({ type: 'speedDialOpen', payload: false });

  const actions = [
    {
      icon: <FiberNewOutlined />,
      name: 'New',
      fun: async () => dispatch({ type: 'drawerOpen', payload: true }),
    },
  ];

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial for Operations"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        open={state.speedDialOpen}
        icon={<SpeedDialIcon />}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.fun}
          />
        ))}
      </SpeedDial>
      <Grid container padding={2}>
        <OperationTableWithMemo operations={operations} />
      </Grid>
      <OperationDrawerWithMemo />
      <Snackbar
        open={state.errors.length !== 0}
        autoHideDuration={3000}
        onClose={handleClose}
        sx={{ zIndex: 10000 }}
      >
        <Stack>
          {state.errors.map((error) => (
            <Alert severity="error">{error}</Alert>
          ))}
        </Stack>
      </Snackbar>
    </Box>
  );
};

const OperationPage = (props: Titleable) => (
  <OperationPageProvider>
    <OperationPageContent {...props} />
  </OperationPageProvider>
);

export default OperationPage;
