import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteValue,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
} from '@mui/material';
import { useQuery } from 'react-query';
import { baseURL, endpoints } from '../endpoints';
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { formatDistance } from 'date-fns';
import { VisibilityOutlined } from '@mui/icons-material';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';

type SelectedOperation = Operation & { categories: Array<Category> };

const mb: number = 3;
const mr: number = 3;

const getEndpoint = (key: string): string =>
  new URL(endpoints.get(key)!!, baseURL).href;

const Analysis = (props: Titleable) => {
  const [operations, setOperations] = useState([]);
  const [results, setResults] = useState([]);
  const { licensePlate } = useParams<string>();
  const [selectedOperation, setSelectedOperation] =
    useState<SelectedOperation>();
  const navigate = useNavigate();

  useEffect(() => props.setTitle(licensePlate ?? ''), []);

  const operationEndpoint = getEndpoint('operationList');

  const getOperations = () =>
    fetch(operationEndpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setOperations(data));

  useQuery('getOperations', getOperations);

  const getResults = () => {
    if (selectedOperation === undefined) return;

    if (selectedOperation.id === undefined) return;

    fetch(
      `https://maintenancebk.pvcarrental.com/api/vehicles/${licensePlate}/operations/${selectedOperation?.id}`,
      { credentials: 'include' }
    )
      .then((resp) => resp.json())
      .then(({ entries }) =>
        setResults(entries.map((e: any) => ({ ...e, id: generateUniqueID() })))
      );
  };

  useQuery('getResults', getResults);

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <>
            {params.value} (
            {formatDistance(new Date(), new Date(params.value as string), {
              addSuffix: true,
            })}
            )
          </>
        );
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <Chip label={params.value} />;
      },
    },
    {
      field: 'workshop',
      headerName: 'Workshop',
      flex: 0.2,
    },
    {
      field: 'amount',
      headerName: 'Invoice Amount',
      flex: 0.2,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.1,
      getActions: (item: any) => [
        <GridActionsCellItem
          icon={<VisibilityOutlined />}
          label="View"
          onClick={(i) => {
            navigate(`${endpoints.get('invoiceListRoute')}/${item.row.id}`);
          }}
        />,
      ],
    },
  ];

  const handleInputChange = (
    se: SyntheticEvent,
    s: string,
    aicr: AutocompleteInputChangeReason
  ) => {
    console.group('operation');
    console.group('string');
    console.log(s);
    console.groupEnd();
    console.groupEnd();
  };

  const handleChange = (
    se: SyntheticEvent,
    av: AutocompleteValue<any, any, any, any>,
    acr: AutocompleteChangeReason
  ) => {
    console.group('operation');
    console.group('autocomplete value');
    console.log(av);
    console.groupEnd();
    console.groupEnd();

    setSelectedOperation(av);
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        padding={2}
        direction="row"
        sx={{ alignItems: 'center' }}
      >
        <Grid item xs={2}>
          <Autocomplete
            getOptionLabel={(option: any) => option.name}
            onChange={(se, av, acr) => handleChange(se, av, acr)}
            onInputChange={(se, s, aicr) => handleInputChange(se, s, aicr)}
            disablePortal
            options={operations}
            renderInput={(params) => (
              <TextField
                {...params}
                key={params.id}
                label="Operation"
                sx={{ width: 300 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={() => getResults()}>
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container padding={2} sx={{ mb }}>
        <DataGridPro
          columns={columns}
          rows={results}
          autoHeight
          isRowSelectable={() => false}
        />
      </Grid>
    </>
  );
};

export default Analysis;
