import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { Box, Grid, SpeedDial, SpeedDialAction } from '@mui/material';
import { FiberNewOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import CarPartTableWithMemo from './CarPartTable';
import { CarPartPageProvider, useCarPartPage } from './context';
import { baseURL, endpoints } from '../endpoints';
import CarPartDrawerWithMemo from './CarPartDrawer';

const endpoint = new URL(endpoints.get('carPartList')!!, baseURL).href;

const CarPartPageContent = (props: Titleable) => {
  const { state, dispatch } = useCarPartPage();
  const [carParts, setCarParts] = useState([]);

  useEffect(() => props.setTitle('Car Parts'), []);

  const handleOpen = async () =>
    dispatch({ type: 'speedDialOpen', payload: true });
  const handleClose = async () =>
    dispatch({ type: 'speedDialOpen', payload: false });

  const getCarParts = () => {
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setCarParts(data));
  };

  useQuery('getCarParts', getCarParts);

  const actions = [
    {
      icon: <FiberNewOutlined />,
      name: 'New',
      fun: async () => dispatch({ type: 'drawerOpen', payload: true }),
    },
  ];

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial for Car Parts"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        open={state.speedDialOpen}
        icon={<SpeedDialIcon />}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.fun}
          />
        ))}
      </SpeedDial>
      <Grid container padding={2}>
        <CarPartTableWithMemo carParts={carParts} />
      </Grid>
      <CarPartDrawerWithMemo />
    </Box>
  );
};

const CarPartPage = (props: Titleable) => (
  <CarPartPageProvider>
    <CarPartPageContent {...props} />
  </CarPartPageProvider>
);

export default CarPartPage;
