import { memo } from 'react';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const columns = [
  {
    field: 'name',
    flex: 1,
    headerName: 'Name',
    filterable: true,
  },
];

const CarPartTable = (props: CarPartTableProps) => (
  <DataGridPro
    autoHeight
    pagination
    components={{
      Toolbar: CustomToolbar,
    }}
    rows={props.carParts}
    columns={columns}
    pageSize={10}
    disableSelectionOnClick={true}
    hideFooterPagination={false}
  />
);

const CarPartTableWithMemo = memo(CarPartTable);

export default CarPartTableWithMemo;
