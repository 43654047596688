import {
  Box,
  Button,
  Drawer,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import { useCategoryPage } from './context';

const NameInput = () => {
  const { state, dispatch } = useCategoryPage();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: 'name', payload: e.target.value }),
    []
  );

  return (
    <TextField
      required
      fullWidth
      label="Name"
      value={state.name}
      onChange={handleChange}
    />
  );
};

const CategoryDrawer = (props: CategoryDrawerProps) => {
  const { state, dispatch } = useCategoryPage();

  const saveCategory = async () => {
    dispatch({ type: 'submit', payload: state.name });
    props.setDrawerOpen(false);
  };

  const cancel = () => {
    props.setDrawerOpen(false);
    props.setSpeedDialOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={props.drawerOpen}
      onClose={cancel}
      style={{ width: '100%', zIndex: 9999 }}
    >
      <Box role="presentation" minWidth="25vw">
        <Box component="form" autoComplete="off" noValidate sx={{ p: 2 }}>
          <Typography variant="h6">Create New Category</Typography>
          <FormControl fullWidth sx={{ mx: 1, mt: '1em' }} variant="standard">
            <NameInput />
          </FormControl>
          <FormControl
            sx={{ mt: '2em', display: 'flex', flexDirection: 'row-reverse' }}
            variant="standard"
          >
            <Box>
              <Button variant="contained" onClick={() => saveCategory()}>
                Save
              </Button>
            </Box>
            <Box sx={{ mr: 1 }}>
              <Button onClick={cancel}>Cancel</Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CategoryDrawer;
