import {
  Box,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  SxProps,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { baseURL, endpoints } from '../endpoints';
import { useEffect, useState } from 'react';
import { format, formatDistance } from 'date-fns';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColTypeDef,
} from '@mui/x-data-grid-pro';
import { BarChartOutlined, VisibilityOutlined } from '@mui/icons-material';

type VehicleInfo = {
  id: number;
  uuid: string;
  imei: string;
  name: string;
  license_plate: string;
  manufacturer: string;
  description: string;
  seats: number;
  gear_type: string;
  storage_capacity: string;
  status_updated_at: string;
};

const mb: number = 3;
const mr: number = 3;

const defaultState: VehicleInfo = {
  id: 0,
  uuid: '',
  imei: '',
  name: '',
  license_plate: '',
  manufacturer: '',
  description: '',
  seats: 0,
  gear_type: '',
  storage_capacity: '',
  status_updated_at: '2000-01-01',
};

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16
}

/**
 * TODO: Add a widget for total expense
 */
const VehiclePage = (props: Titleable) => {
  const [data, setData] = useState<VehicleInfo>(defaultState);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const { licensePlate } = useParams<string>();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => props.setTitle(licensePlate ?? ''), []);

  const endpoint = new URL(endpoints.get('vehicleInfo')!!, baseURL).href;

  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const invoiceEndpoint = new URL(endpoints.get('vehicleInfo')!!, baseURL).href;

  const getInvoices = (page = 0) =>
    fetch(`${invoiceEndpoint}/${licensePlate}/invoices?page=${page + 1}`, {
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((data) => {
        setInvoices(data.data);
        setRowCount(data.count);
      });

  const { isLoading } = useQuery([`getInvoiceByLicensePlate`, page], () =>
    getInvoices(page)
  );

  /**
   * TODO: Handle invalid license_plate
   */
  useQuery(`getVehicle${licensePlate}`, () => {
    fetch(`${endpoint}/${licensePlate}`, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setData(data));
  });

  useQuery(`getTotal${licensePlate}`, () => {
    fetch(`https://maintenancebk.pvcarrental.com/api/vehicles/${licensePlate}/total`, {credentials: 'include'})
      .then((resp) => resp.json())
      .then(data => setTotal(data.total));
  })

  const usdPrice: GridColTypeDef = {
    type: 'number',
    flex: 0.2,
    valueFormatter: ({ value }) =>
      `${new Intl.NumberFormat('en-US').format(value as number)} MMK`,
    cellClassName: 'font-tabular-nums',
  };

  const dateFormat: GridColTypeDef = {
    valueFormatter: ({ value }) =>
      format(new Date(value as string), 'yyyy MMMM dd'),
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      filterable: true,
    },
    {
      field: 'license_plate',
      headerName: 'License Plate',
      flex: 0.2,
      filterable: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.2,
      filterable: true,
      ...dateFormat,
    },
    {
      field: 'workshop',
      headerName: 'Workshop',
      flex: 0.2,
      filterable: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.2,
      filterable: true,
      ...usdPrice,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: (item: any) => [
        <GridActionsCellItem
          icon={<VisibilityOutlined />}
          label="View"
          onClick={(i) => {
            navigate(`${endpoints.get('invoiceListRoute')}/${item.row.id}`);
          }}
        />,
      ],
    },
  ];

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });

  return (
    <>
      <Grid container padding={2} direction="column">
        <Typography variant="h5">Vehicle Information</Typography>
        <Grid item>
          <List>
            <ListItem>
              <ListItemText
                primary="License Plate"
                secondary={data.license_plate}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Gear Type" secondary={data.gear_type} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Name" secondary={data.name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Status Updated At"
                secondary={`${format(
                  new Date(data.status_updated_at),
                  'yyyy MMMM dd'
                )} (${formatDistance(
                  new Date(),
                  new Date(data.status_updated_at),
                  { addSuffix: true }
                )})`}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid>
          <Typography variant="h5" sx={{ mb }}>
            Total cost to date
          </Typography>
          <Typography variant="h6" sx={{ mb }}>
            {formatter.format(total)} MMK
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="h5" sx={{ mb }}>
            Invoices for {data.license_plate}
          </Typography>
          <DataGridPro
            autoHeight
            pagination
            loading={isLoading}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            rowCount={rowCount}
            rows={invoices}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick={true}
            hideFooterPagination={false}
          />
        </Grid>
      </Grid>
      <Box sx={{ height: 48 }} />
      <Fab variant="extended" color='primary' sx={fabStyle as SxProps }
           onClick={() => navigate(`/analyze/${licensePlate}`)}
      >
        <BarChartOutlined />
        Analyze
      </Fab>
    </>
  );
};

export default VehiclePage;
