import React, {
  ChangeEvent,
  SyntheticEvent,
  useState,
  useRef,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
} from 'react';
import {
  Alert,
  AppBar,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteValue,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Snackbar,
  Stack,
  styled,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { DesktopDatePicker } from '@mui/lab';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import ItemSelection from './ItemSelection';
import { baseURL, endpoints } from '../endpoints';
import {
  AttachFileOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { uniq } from 'ramda';
import { format } from 'date-fns';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { TransitionProps } from '@mui/material/transitions';

const mb: number = 3;
const mr: number = 3;

const Transition = forwardRef(function Transaction(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Input = styled('input')({
  display: 'none',
});

const getEndpoint = (key: string): string =>
  new URL(endpoints.get(key)!!, baseURL).href;

const schema = yup.object().shape({
  license_plate: yup.string().length(11).required().label('License Plate'),
  workshop_id: yup.number().required().label('Workshop'),
  amount: yup.number().required(),
  date: yup
    .string()
    .matches(/\d{4}-\d{2}-\d{2}/g, 'Invalid Date format')
    .label('Date'),
});

const endpoint = new URL(endpoints.get('invoiceList')!!, baseURL).href;

const InvoiceForm = (props: Titleable) => {
  const [value, setValue] = useState<Date | null>(new Date());
  const [workshops, setWorkshops] = useState<WorkshopList>([]);
  const [selectedItems, setSelectedItems] = useState<Array<SelectedItem>>([]);
  const [amount, setAmount] = useState<number>(0);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentMessages, setCurrentMessages] = useState<Array<string>>([]);
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => props.setTitle('New Invoice'), []);

  const [selectedLicensePlate, setSelectedLicensePlate] = useState<string>('');
  const [selectedWorkshop, setSelectedWorkshop] = useState<
    Workshop | undefined
  >(undefined);

  const workshopEndpoint = getEndpoint('workshopList');

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };

  const handleAmountChange = (
    ce: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setAmount(parseInt(ce.target.value));

  const cancelForm = () => navigate('/invoices');

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const getWorkshops = () =>
    fetch(workshopEndpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setWorkshops(data));

  useQuery('getWorkshops', getWorkshops);

  const getCarPart = (params: GridValueGetterParams): string => {
    const operation = params?.getValue(params.id, 'operation') as Operation;

    return (operation?.car_part as string) || '';
  };

  const [vehicles, setVehicles] = useState<VehicleList>([]);

  const vehicleEndpoint = getEndpoint('vehicleCount');

  const getVehicles = () => {
    fetch(vehicleEndpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => {
        setVehicles(
          uniq<string>(data).map((license_plate: string, idx: number) => ({
            id: idx,
            label: license_plate,
            license_plate,
          }))
        );
      });
  };

  useQuery('getVehicles', getVehicles);

  const handleSubmitAfterReview = () => {
    const invoiceEntries = selectedItems.map((d) => ({
      category_id: d.category.id,
      operation_id: d.operation.id,
      quantity: d.quantity,
    }));

    const payload = {
      invoice: {
        date: format(value!! as Date, 'yyyy-MM-dd'),
        amount,
        license_plate: selectedLicensePlate,
        workshop_id: selectedWorkshop?.id,
        invoice_entries: invoiceEntries,
      },
    };

    schema
      .validate(payload.invoice)
      .then((valid) => {
        if (!valid) return;

        const body = new FormData();

        for (let i = 0; i < (fileRef as any).current.files.length; ++i) {
          body.append('file[]', (fileRef as any).current.files.item(i));
        }

        body.append('invoice', JSON.stringify(payload));

        fetch(endpoint, {
          method: 'POST',
          credentials: 'include',
          body,
        }).then(() => navigate('/invoices'));
      })
      .catch((error) => {
        setSnackBarOpen(true);
        setCurrentMessages(error.errors);
      });
  };

  const handleSubmit = () => {
    const invoiceEntries = selectedItems.map((d) => ({
      category_id: d.category.id,
      operation_id: d.operation.id,
      quantity: d.quantity,
    }));

    const payload = {
      invoice: {
        date: format(value!! as Date, 'yyyy-MM-dd'),
        amount,
        license_plate: selectedLicensePlate,
        workshop_id: selectedWorkshop?.id,
        invoice_entries: invoiceEntries,
      },
    };

    schema
      .validate(payload.invoice)
      .then((valid) => {
        if (!valid) return;

        const body = new FormData();

        for (let i = 0; i < (fileRef as any).current.files.length; ++i) {
          body.append('file[]', (fileRef as any).current.files.item(i));
        }

        body.append('invoice', JSON.stringify(payload));

        setDialogOpen(true);

        return;

        fetch(endpoint, {
          method: 'POST',
          credentials: 'include',
          body,
        }).then(() => navigate('/invoices'));
      })
      .catch((error) => {
        setSnackBarOpen(true);
        setCurrentMessages(error.errors);
      });
  };

  const columns = [
    {
      field: 'operation',
      headerName: 'Name',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<Operation>) => {
        return <>{params.value.name}</>;
      },
    },
    {
      field: 'car_part',
      headerName: 'Car Part',
      flex: 0.2,
      valueGetter: getCarPart,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<Category>) => {
        return <Chip label={params.value.name} />;
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.2,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteOutlined />}
          label="Edit"
          onClick={() =>
            setSelectedItems(selectedItems.filter((si) => si.id !== params.id))
          }
        />,
      ],
    },
  ];

  const handleDialogClose = () => setDialogOpen(false);

  const displaySize = (size: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    });

    if (size < 1_000) return `${formatter.format(size)} B`;

    if (size < 1_000_000) return `${formatter.format(size / 1_000)} KiB`;

    return `${formatter.format(size / 1_000_000)} MiB`;
  };

  const handleLicensePlateChange = (
    se: SyntheticEvent,
    av: AutocompleteValue<any, any, any, any>,
    _acr: AutocompleteChangeReason
  ) => {
    setSelectedLicensePlate(av?.license_plate);
  };

  const handleWorkshopChange = (
    se: SyntheticEvent,
    av: AutocompleteValue<any, any, any, any>,
    _acr: AutocompleteChangeReason
  ) => {
    setSelectedWorkshop(av ?? undefined);
  };

  return (
    <Box padding={2}>
      <Typography variant="h5" sx={{ mb }}>
        File a new invoice
      </Typography>
      <Grid container direction="column">
        <Grid container direction="row">
          <Box>
            <Autocomplete
              disablePortal
              options={vehicles}
              onChange={(se, av, acr) => handleLicensePlateChange(se, av, acr)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="License Plate"
                  sx={{ width: 300, mb, mr }}
                />
              )}
            />
          </Box>
          <Box>
            <DesktopDatePicker
              inputFormat="yyyy/MM/dd"
              label="Date"
              onChange={handleChange}
              value={value}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 300, mb, mr }} />
              )}
            />
          </Box>
          <Box>
            <Autocomplete
              disablePortal
              onChange={(se, av, acr) => handleWorkshopChange(se, av, acr)}
              getOptionLabel={(o) => o.name}
              options={workshops}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Workshops"
                  sx={{ width: 300, mb }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ mb }}>
        Selected Items
      </Typography>
      <Grid container sx={{ mb }}>
        <DataGridPro columns={columns} rows={selectedItems} autoHeight />
      </Grid>
      <Typography variant="h6" sx={{ mb }}>
        Select item
      </Typography>
      <Grid container direction="column">
        <ItemSelection
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </Grid>
      <Typography variant="h6" sx={{ mb }}>
        Enter Total Amount
      </Typography>
      <Box sx={{ mb, mr }}>
        <TextField
          type="number"
          label="Amount"
          defaultValue={amount}
          onChange={handleAmountChange}
        />
      </Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Attach invoice files
      </Typography>
      <Grid container direction="row">
        <label htmlFor="contained-button-file">
          <Box sx={{ mb: 3 }}>
            <List dense>
              {(fileRef as any).current &&
                [].map.call((fileRef as any).current?.files, (file: any) => (
                  <ListItem>
                    <ListItemIcon>
                      <AttachFileOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={displaySize(file.size)}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
          <Input
            accept="image/*,application/pdf"
            multiple
            id="contained-button-file"
            type="file"
            ref={fileRef}
          />
          <Button variant="contained" component="span">
            Upload
          </Button>
        </label>
      </Grid>
      <Grid container direction="row" flexDirection="row-reverse">
        <Button variant="contained" onClick={handleSubmit}>
          Review and Submit
        </Button>
        <Button sx={{ mr }} onClick={cancelForm}>
          Cancel
        </Button>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
            >
              <CloseOutlined />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Are you sure?
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmitAfterReview}>
              submit
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <ListItemText
              primary="License Plate"
              secondary={selectedLicensePlate}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Workshop"
              secondary={selectedWorkshop?.name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Date"
              secondary={format(value!! as Date, 'yyyy-MM-dd')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Amount"
              secondary={`${new Intl.NumberFormat('en-US').format(amount)} MMK`}
            />
          </ListItem>
        </List>
        <Grid container sx={{ mb, px: 2 }}>
          <DataGridPro
            columns={columns.filter((column) => column.field !== 'actions')}
            rows={selectedItems}
            autoHeight
          />
        </Grid>
        <Box sx={{ px: 2 }}>
          <Typography variant="h6">Attachments</Typography>
          <List dense>
            {(fileRef as any).current &&
              [].map.call((fileRef as any).current?.files, (file: any) => (
                <ListItem>
                  <ListItemIcon>
                    <AttachFileOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    secondary={displaySize(file.size)}
                  />
                </ListItem>
              ))}
          </List>
        </Box>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Stack sx={{ ml: 10 }}>
          {currentMessages.map((message) => (
            <Alert
              severity="error"
              key={generateUniqueID()}
              sx={{ width: '100%' }}
              onClose={handleClose}
            >
              {message}
            </Alert>
          ))}
        </Stack>
      </Snackbar>
    </Box>
  );
};

export default InvoiceForm;
