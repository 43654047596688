export const endpoints = new Map([
  ['carPartCount', '/api/count/car_parts'],
  ['categoryCount', '/api/count/categories'],
  ['invoiceCount', '/api/count/invoices'],
  ['operationCount', '/api/count/operations'],
  ['vehicleCount', '/api/vehicles'],
  ['workshopCount', '/api/count/workshops'],

  ['vehicleInfo', '/api/vehicles'],

  ['carPartListRoute', '/car_parts'],
  ['categoryListRoute', '/categories'],
  ['invoiceListRoute', '/invoices'],
  ['operationListRoute', '/operations'],
  ['vehicleListRoute', '/vehicles'],
  ['workshopListRoute', '/workshops'],
  ['invoiceFormRoute', '/create_invoice'],
  ['vehicleInfoRoute', '/vehicle'],
  ['singleInvoiceRoute', '/invoices/:invoiceId'],
  ['analysisRoute', '/analyze/:licensePlate'],

  ['vehicleRoute', '/vehicle/:licensePlate'],
  ['carPartList', '/api/car_parts'],
  ['workshopList', '/api/workshops'],
  ['invoiceList', '/api/invoices'],
  ['invoiceListByVehicle', '/api/invoices'],
  ['operationList', '/api/operations'],
  ['categoryList', '/api/categories'],

  ['createWorkshop', '/api/workshops'],
  ['createCategory', '/api/categories'],
  ['createOperation', '/api/operations'],

  ['totalInvoices', '/api/total/invoices']
]);

export const baseURL = 'https://maintenancebk.pvcarrental.com';
