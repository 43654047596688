import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { EditOutlined } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { memo } from 'react';

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const OperationTable = (props: OperationTableProps) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.2,
      filterable: true,
    },
    {
      field: 'car_part',
      headerName: 'Car Part',
      flex: 0.2,
      filterable: true,
    },
    {
      field: 'categories',
      headerName: 'Category',
      flex: 0.2,
      filterable: false,
      renderCell: (params: any) =>
        params?.value.map((x: Category) => (
          <Chip label={x.name} sx={{ mr: 1 }} />
        )),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: () => [
        <GridActionsCellItem icon={<EditOutlined />} label="Edit" />,
      ],
    },
  ];

  return (
    <DataGridPro
      autoHeight
      pagination
      components={{
        Toolbar: CustomToolbar,
      }}
      rows={props.operations}
      columns={columns}
      pageSize={10}
      disableSelectionOnClick={true}
      hideFooterPagination={false}
    />
  );
};

const OperationTableWithMemo = memo(OperationTable);

export default OperationTableWithMemo;
