import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseURL, endpoints } from '../endpoints';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { VisibilityOutlined } from '@mui/icons-material';

const VehicleListPage = (props: Titleable) => {
  const [vehicles, setVehicles] = useState<VehicleList>([]);
  const navigate = useNavigate();
  const endpoint = new URL(endpoints.get('vehicleCount')!!, baseURL).href;

  useEffect(() => props.setTitle('Vehicles'), []);

  const getVehicles = () => {
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) =>
        data.map((license_plate: string, idx: number) => ({
          id: idx,
          license_plate,
        }))
      )
      .then((data) => setVehicles(data));
  };

  useQuery('getVehicles', getVehicles);

  const columns = [
    {
      field: 'license_plate',
      headerName: 'License Plate',
      flex: 0.8,
      filterable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: (item: any) => [
        <GridActionsCellItem
          icon={<VisibilityOutlined />}
          label="View"
          onClick={(i) => {
            navigate(
              `${endpoints.get('vehicleInfoRoute')}/${item.row.license_plate}`
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Grid container padding={2}>
      <DataGridPro
        autoHeight
        pagination
        rows={vehicles}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick={true}
      />
    </Grid>
  );
};

export default VehicleListPage;
