import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { baseURL, endpoints } from '../endpoints';
import { useQuery } from 'react-query';
import { EditOutlined } from '@mui/icons-material';

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const CategoryTable = () => {
  const [categories, setCategories] = useState([]);
  const endpoint = new URL(endpoints.get('categoryList')!!, baseURL).href;

  const getCategories = () =>
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setCategories(data));

  useQuery('getCategories', getCategories);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.8,
      filterable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: () => [
        <GridActionsCellItem icon={<EditOutlined />} label="Edit" />,
      ],
    },
  ];

  return (
    <DataGridPro
      autoHeight
      pagination
      components={{
        Toolbar: CustomToolbar,
      }}
      rows={categories}
      columns={columns}
      pageSize={10}
      disableSelectionOnClick={true}
      hideFooterPagination={false}
    />
  );
};

export default CategoryTable;
