import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { baseURL, endpoints } from '../endpoints';
import { useQuery } from 'react-query';
import { EditOutlined } from '@mui/icons-material';

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const CategoryTable = () => {
  const [workshops, setWorkshops] = useState<WorkshopList>([]);
  const endpoint = new URL(endpoints.get('workshopList')!!, baseURL).href;

  const getWorkshops = () =>
    fetch(endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setWorkshops(data));

  useQuery('getWorkshops', getWorkshops);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.4,
      filterable: true,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 0.4,
      filterable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: () => [
        <GridActionsCellItem icon={<EditOutlined />} label="Edit" />,
      ],
    },
  ];

  return (
    <DataGridPro
      autoHeight
      pagination
      components={{
        Toolbar: CustomToolbar,
      }}
      rows={workshops}
      columns={columns}
      pageSize={10}
      disableSelectionOnClick={true}
      hideFooterPagination={false}
    />
  );
};

export default CategoryTable;
