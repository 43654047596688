import { Box, IconButton, Paper } from '@mui/material';
import { BuildOutlined, Refresh } from '@mui/icons-material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

type StatsProps = {
  endpoint: string;
  title: string;
  listRoute: string;
};

const Stats = (props: StatsProps) => {
  const [count, setCount] = useState<number>(0);

  const getVehicles = () => {
    fetch(props.endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ count }) => setCount(count));
  };

  const { isLoading, isFetching } = useQuery(`get${props.title}`, getVehicles);

  const refresh = () => {
    fetch(props.endpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ count }) => setCount(count));
  };

  return (
    <Paper
      sx={{
        p: 2,
        textAlign: 'right',
      }}
    >
      <Box
        sx={{
          fontSize: 36,
          fontWeight: 'medium',
        }}
      >
        {isFetching || isLoading ? '--' : count}
      </Box>
      <Box>{props.title}</Box>
      <Box sx={{ marginTop: 1 }}>
        <IconButton size="small" onClick={() => refresh()}>
          <Refresh />
        </IconButton>
        <IconButton size="small" component={Link} to={props.listRoute}>
          <BuildOutlined />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default Stats;
