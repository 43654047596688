import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { baseURL, endpoints } from '../endpoints';
import React, { useEffect, useState } from 'react';
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { format, formatDistance } from 'date-fns';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { last } from 'ramda';

const mr: number = 3;
const mb: number = 3;

const defaultInvoice: SingleInvoiceState = {
  id: 0,
  amount: 0,
  license_plate: '',
  workshop: '',
  date: '2000-01-01',
  invoice_entries: [],
  documents: [],
};

const SingleInvoicePage = (props: Titleable) => {
  const [invoice, setInvoice] = useState<SingleInvoiceState>(defaultInvoice);
  const { invoiceId } = useParams<string>();
  const endpoint = new URL(endpoints.get('invoiceList')!!, baseURL).href;

  useEffect(() => props.setTitle('Invoice'), []);

  const getInvoice = () =>
    fetch(`${endpoint}/${invoiceId}`, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setInvoice(data));

  useQuery(`getInvoice${invoiceId}`, getInvoice);

  const columns = [
    {
      field: 'operation_name',
      headerName: 'Operation',
      flex: 0.2,
      filterable: true,
    },
    {
      field: 'category_name',
      headerName: 'Category',
      flex: 0.2,
      filterable: true,
      renderCell: (params: GridRenderCellParams<Operation>) => {
        return <Chip label={params.value} />;
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.2,
      filterable: true,
    },
  ];

  return (
    <Grid container padding={2} direction="column">
      <Typography variant="h5">Invoice Information</Typography>
      <Grid item>
        <List>
          <ListItem>
            <ListItemText
              primary="License Plate"
              secondary={invoice.license_plate}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Workshop" secondary={invoice.workshop} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Amount"
              secondary={`${new Intl.NumberFormat('en-US').format(
                invoice.amount as number
              )} MMK`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Date"
              secondary={`${format(
                new Date(invoice.date),
                'yyyy MMMM dd'
              )} (${formatDistance(new Date(), new Date(invoice.date), {
                addSuffix: true,
              })})`}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid container padding={2} direction="column">
        <DataGridPro
          autoHeight
          pagination
          rows={invoice.invoice_entries}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick={true}
          hideFooterPagination={false}
        />
      </Grid>
      <Typography variant="h6">Attachments</Typography>
      <Stack direction="row" spacing={1} padding={2}>
        {invoice.documents.map((document) => (
          <Chip
            onClick={() => window.open(document.url, '_blank')}
            label={last(document.url.split('/'))}
          />
        ))}
      </Stack>
    </Grid>
  );
};

export default SingleInvoicePage;
