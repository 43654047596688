import { createContext, ReactNode, useContext, useReducer } from 'react';
import { baseURL, endpoints } from '../endpoints';

const defaultState = { name: '' };

const categoryPageReducer = (state: CategoryPageState, action: Action) => {
  switch (action.type) {
    case 'name':
      return { name: action.payload };
    case 'submit':
      const newCategoryRequest: NewCategoryRequest = {
        category: {
          name: state.name,
        },
      };

      const endpoint = new URL(endpoints.get('createCategory')!!, baseURL).href;
      fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(newCategoryRequest),
        credentials: 'include',
      });
      return state;
    default:
      throw new Error();
  }
};

const CategoryPageContext = createContext<
  { state: CategoryPageState; dispatch: Dispatch } | undefined
>(undefined);

export const CategoryPageProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(categoryPageReducer, defaultState);

  return (
    <CategoryPageContext.Provider value={{ state, dispatch }}>
      {children}
    </CategoryPageContext.Provider>
  );
};

export function useCategoryPage() {
  const context = useContext(CategoryPageContext);

  if (!context)
    throw new Error(
      'useCategoryPage must be used inside a CategoryPageProvider'
    );

  return context;
}
