import { createContext, ReactNode, useContext, useReducer } from 'react';
import { baseURL, endpoints } from '../endpoints';

const defaultState = { name: '', address: '' };

const workshopPageReducer = (
  state: WorkshopPageState,
  action: WorkshopAction
) => {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload };
    case 'address':
      return { ...state, address: action.payload };
    case 'submit':
      const newWorkshopRequest: NewWorkshopRequest = {
        workshop: {
          name: state.name,
          address: state.address,
        },
      };

      const endpoint = new URL(endpoints.get('createWorkshop')!!, baseURL).href;
      fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(newWorkshopRequest),
        credentials: 'include',
      });
      return state;
    default:
      throw new Error();
  }
};

const WorkshopPageContext = createContext<
  { state: WorkshopPageState; dispatch: WorkshopDispatch } | undefined
>(undefined);

export const WorkshopPageProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(workshopPageReducer, defaultState);

  return (
    <WorkshopPageContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkshopPageContext.Provider>
  );
};

export function useWorkshopPage() {
  const context = useContext(WorkshopPageContext);

  if (!context)
    throw new Error(
      'useWorkshopPage must be used inside a WorkshopPageProvider'
    );

  return context;
}
