import { baseURL, endpoints } from '../endpoints';
import { createContext, ReactNode, useContext, useReducer } from 'react';
import * as yup from 'yup';

const emptyCategoryArray: Array<Category> = [];
const defaultState = {
  name: '',
  car_part_id: 0,
  categories: emptyCategoryArray,
  speedDialOpen: false,
  drawerOpen: false,
  hasError: false,
  errors: [],
};

const schema = yup.object().shape({
  name: yup.string().required().min(3).label('Name'),
  car_part_id: yup
    .number()
    .required()
    .positive('A Car Part must be selected')
    .label('Car Part'),
  categories: yup.array().min(1).label('Categories'),
});

const endpoint = new URL(endpoints.get('createOperation')!!, baseURL).href;

const operationPageReducer = (
  state: OperationPageState,
  action: OperationAction
): OperationPageState => {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload as string };
    case 'car_part':
      return action.payload !== undefined
        ? { ...state, car_part_id: action.payload as number }
        : state;
    case 'categories':
      return action.payload !== undefined
        ? { ...state, categories: action.payload as Array<Category> }
        : state;
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload as boolean };
    case 'speedDialOpen':
      return { ...state, speedDialOpen: action.payload as boolean };
    case 'reset':
      return defaultState;
    case 'submit':
      const newOperationRequest: NewOperationRequest = {
        operation: {
          name: state.name,
          car_part_id: state.car_part_id,
          categories: state.categories.map((c: Category) => c.id),
        },
      };

      try {
        schema.validateSync(newOperationRequest.operation);
        fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(newOperationRequest),
          credentials: 'include',
        });

        return {
          ...state,
          ...defaultState,
          hasError: false,
          errors: [],
          drawerOpen: false,
          speedDialOpen: false,
        };
      } catch (e: any) {
        return { ...state, hasError: true, errors: e.errors };
      }
    default:
      throw new Error();
  }
};

const OperationPageContext = createContext<
  { state: OperationPageState; dispatch: OperationDispatch } | undefined
>(undefined);

export const OperationPageProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(operationPageReducer, defaultState);

  return (
    <OperationPageContext.Provider value={{ state, dispatch }}>
      {children}
    </OperationPageContext.Provider>
  );
};

export function useOperationPage() {
  const context = useContext(OperationPageContext);

  if (!context)
    throw new Error(
      'useOperationPage must be used inside a OperationPageProvider'
    );

  return context;
}
