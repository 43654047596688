import { Box, Grid, Link, SpeedDial, SpeedDialAction } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColTypeDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {
  FiberNewOutlined,
  FileDownloadOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { baseURL, endpoints } from '../endpoints';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

const CustomToolbar = (props: Titleable) => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarExport />
  </GridToolbarContainer>
);

const InvoicePage = (props: Titleable) => {
  const [invoices, setInvoices] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => props.setTitle('Invoices'), []);

  const endpoint = new URL(endpoints.get('invoiceList')!!, baseURL).href;

  const navigate = useNavigate();

  const getInvoices = (page = 0) =>
    fetch(`${endpoint}?page=${page + 1}`, { credentials: 'include' })
      .then((resp) => resp.json())
      .then((data) => {
        setInvoices(data.data);
        setRowCount(data.count);
      });

  const { isLoading } = useQuery(['getInvoices', page], () =>
    getInvoices(page)
  );

  const usdPrice: GridColTypeDef = {
    type: 'number',
    flex: 0.2,
    valueFormatter: ({ value }) =>
      `${new Intl.NumberFormat('en-US').format(value as number)} MMK`,
    cellClassName: 'font-tabular-nums',
  };

  const dateFormat: GridColTypeDef = {
    valueFormatter: ({ value }) =>
      format(new Date(value as string), 'yyyy MMMM dd'),
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      filterable: true,
    },
    {
      field: 'license_plate',
      headerName: 'License Plate',
      flex: 0.2,
      filterable: true,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Link
          component={RouterLink}
          to={`/vehicle/${params.value}`}
          underline="none"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.2,
      filterable: true,
      ...dateFormat,
    },
    {
      field: 'workshop',
      headerName: 'Workshop',
      flex: 0.2,
      filterable: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.2,
      filterable: true,
      ...usdPrice,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 0.2,
      getActions: (item: any) => [
        <GridActionsCellItem
          icon={<VisibilityOutlined />}
          label="View"
          onClick={(i) => {
            navigate(`${endpoints.get('invoiceListRoute')}/${item.row.id}`);
          }}
        />,
      ],
    },
  ];

  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const handleOpen = () => setSpeedDialOpen(true);
  const handleClose = () => setSpeedDialOpen(false);

  const actions = [
    {
      icon: <FiberNewOutlined />,
      name: 'New',
      fun: () => navigate('/create_invoice'),
    },
    {
      icon: <FileDownloadOutlined />,
      name: 'Export',
    },
  ];

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial for Categories"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        open={speedDialOpen}
        icon={<SpeedDialIcon />}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.fun}
          />
        ))}
      </SpeedDial>
      <Grid container padding={2}>
        <DataGridPro
          autoHeight
          pagination
          loading={isLoading}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: CustomToolbar,
          }}
          rowCount={rowCount}
          rows={invoices}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick={true}
          hideFooterPagination={false}
        />
      </Grid>
    </Box>
  );
};

export default InvoicePage;
