import { Box, IconButton, Paper } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { baseURL, endpoints } from '../../endpoints';

const endpoint = new URL(endpoints.get('totalInvoices')!!, baseURL).href;

const TotalCosts = () => {
  const [count, setCount] = useState<number>(0);

  const getTotalExpenses = () => {
    fetch(endpoint, {
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then(({ total }) => setCount(total));
  };

  const { isLoading, isFetching } = useQuery(
    'getTotalExpenses',
    getTotalExpenses
  );

  const refresh = () => {
    fetch(endpoint, {
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then(({ total }) => setCount(total));
  };

  return (
    <Paper
      sx={{
        p: 2,
        textAlign: 'right',
      }}
    >
      <Box
        sx={{
          fontSize: 36,
          fontWeight: 'medium',
        }}
      >
        {isFetching || isLoading
          ? '--'
          : `${new Intl.NumberFormat('en-US').format(count)} MMK`}
      </Box>
      <Box>Total Expenses</Box>
      <Box sx={{ marginTop: 1 }}>
        <IconButton size="small" onClick={() => refresh()}>
          <Refresh />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default TotalCosts;
