import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteValue,
  Box,
  Button,
  Drawer,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  SyntheticEvent,
  memo,
  useCallback,
  useState,
} from 'react';
import { useOperationPage } from './context';
import { baseURL, endpoints } from '../endpoints';
import { useQuery } from 'react-query';

const mb: number = 3;
const mr: number = 3;

const categoryEndpoint = new URL(endpoints.get('categoryList')!!, baseURL).href;
const carPartEndpoint = new URL(endpoints.get('carPartList')!!, baseURL).href;

const CategoryInput = (props: { categories: Array<Category> }) => {
  const { dispatch } = useOperationPage();

  const handleCategoriesChange = useCallback(
    (
      se: SyntheticEvent,
      av: AutocompleteValue<any, any, any, any>,
      acr: AutocompleteChangeReason
    ) => dispatch({ type: 'categories', payload: av }),
    []
  );

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      getOptionLabel={(option: Category) => option.name}
      disablePortal
      options={props.categories}
      onChange={(se, av, acr) => handleCategoriesChange(se, av, acr)}
      renderInput={(params) => (
        <TextField {...params} label="Categories" sx={{ mb }} />
      )}
    />
  );
};

const CategoryInputWithMemo = memo(CategoryInput);

const CarPartInput = (props: { carParts: Array<CarPart> }) => {
  const { dispatch } = useOperationPage();

  const handleCarPartChange = useCallback(
    (
      se: SyntheticEvent,
      av: AutocompleteValue<any, any, any, any>,
      acr: AutocompleteChangeReason
    ) => dispatch({ type: 'car_part', payload: av?.id }),
    []
  );

  return (
    <Autocomplete
      getOptionLabel={(option: CarPart) => option.name}
      disablePortal
      options={props.carParts}
      onChange={(se, av, acr) => handleCarPartChange(se, av, acr)}
      renderInput={(params) => (
        <TextField {...params} label="Car Part" sx={{ mb }} />
      )}
    />
  );
};

const CarPartInputWithMemo = memo(CarPartInput);

const NameInput = () => {
  const { state, dispatch } = useOperationPage();
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'name', payload: e.target.value });

  return (
    <TextField
      fullWidth
      label="Name"
      onChange={handleChange}
      required
      value={state.name}
    />
  );
};

const OperationDrawer = () => {
  const { state, dispatch } = useOperationPage();

  const [carParts, setCarParts] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCarParts = () => {
    fetch(carPartEndpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setCarParts(data));
  };

  const getCategories = () => {
    fetch(categoryEndpoint, { credentials: 'include' })
      .then((resp) => resp.json())
      .then(({ data }) => setCategories(data));
  };

  useQuery('getCategories', getCategories);
  useQuery('getCarParts', getCarParts);

  const saveOperation = async () =>
    dispatch({ type: 'submit', payload: state.name });

  const cancel = async () => dispatch({ type: 'reset', payload: true });

  return (
    <Drawer
      anchor="right"
      open={state.drawerOpen}
      onClose={cancel}
      style={{ width: '100%', zIndex: 9999 }}
    >
      <Box role="presentation" minWidth="25vw" maxWidth="25vw">
        <Box component="form" autoComplete="off" noValidate sx={{ p: 2 }}>
          <Typography variant="h6">Create New Operation</Typography>
          <FormControl
            fullWidth
            sx={{ mx: 1, mt: '1em', mb }}
            variant="standard"
          >
            <NameInput />
          </FormControl>
          <FormControl fullWidth sx={{ mx: 1, mt: '1em' }} variant="standard">
            <CarPartInputWithMemo carParts={carParts} />
          </FormControl>
          <FormControl fullWidth sx={{ mx: 1, mt: '1em' }} variant="standard">
            <CategoryInputWithMemo categories={categories} />
          </FormControl>
          <FormControl
            sx={{ mt: '2em', display: 'flex', flexDirection: 'row-reverse' }}
            variant="standard"
          >
            <Box>
              <Button variant="contained" onClick={() => saveOperation()}>
                Save
              </Button>
            </Box>
            <Box sx={{ mr: 1 }}>
              <Button onClick={cancel}>Cancel</Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </Drawer>
  );
};

const OperationDrawerWithMemo = memo(OperationDrawer);

export default OperationDrawerWithMemo;
