import { createContext, useContext, useReducer, ReactNode } from 'react';
import * as yup from 'yup';
import { baseURL, endpoints } from '../endpoints';

type CarPartPageState = {
  name: string;
  hasError: boolean;
  errors: Array<string>;
  drawerOpen: boolean;
  speedDialOpen: boolean;
};

type Action = {
  type: 'name' | 'drawerOpen' | 'speedDialOpen' | 'submit';
  payload: string | boolean;
};

type Dispatch = (action: Action) => void;

const defaultState = {
  name: '',
  hasError: false,
  errors: [],
  drawerOpen: false,
  speedDialOpen: false,
};

type NewCarPartRequestBody = {
  name: string;
};

type NewCarPartRequest = {
  car_part: NewCarPartRequestBody;
};

const schema = yup.object().shape({
  name: yup.string().required().min(3).label('Name'),
});

const endpoint = new URL(endpoints.get('carPartList')!!, baseURL).href;

function carPartPageReducer(state: CarPartPageState, action: Action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload as string };
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload as boolean };
    case 'speedDialOpen':
      return { ...state, speedDialOpen: action.payload as boolean };
    case 'submit':
      const newCarPartRequest: NewCarPartRequest = {
        car_part: {
          name: state.name,
        },
      };

      try {
        schema.validateSync(newCarPartRequest.car_part);

        fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(newCarPartRequest),
          credentials: 'include',
        });

        return {
          ...state,
          name: '',
          hasError: false,
          errors: [],
          drawerOpen: false,
          speedDialOpen: false,
        };
      } catch (e: any) {
        return { ...state, hasError: true, errors: e.errors };
      }
    default:
      throw new Error();
  }
}

const CarPartPageContext = createContext<
  { state: CarPartPageState; dispatch: Dispatch } | undefined
>(undefined);

export function CarPartPageProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(carPartPageReducer, defaultState);

  return (
    <CarPartPageContext.Provider value={{ state, dispatch }}>
      {children}
    </CarPartPageContext.Provider>
  );
}

export function useCarPartPage() {
  const context = useContext(CarPartPageContext);

  if (!context)
    throw new Error('useCarPartPage must be used inside a CarPartPageProvider');

  return context;
}
