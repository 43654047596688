import {
  Box,
  Button,
  Drawer,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import { useWorkshopPage } from './context';

const mb: number = 3;
const mr: number = 3;

const NameInput = () => {
  const { state, dispatch } = useWorkshopPage();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: 'name', payload: e.target.value }),
    []
  );

  const handleAddressChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: 'address', payload: e.target.value }),
    []
  );

  return (
    <>
      <Box sx={{ mb }}>
        <TextField
          required
          fullWidth
          label="Name"
          value={state.name}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ mb }}>
        <TextField
          required
          fullWidth
          label="Address"
          value={state.address}
          onChange={handleAddressChange}
        />
      </Box>
    </>
  );
};

const WorkshopDrawer = (props: WorkshopDrawerProps) => {
  const { state, dispatch } = useWorkshopPage();

  const saveWorkshop = async () => {
    dispatch({ type: 'submit', payload: state.name });
    props.setDrawerOpen(false);
  };

  const cancel = () => {
    props.setDrawerOpen(false);
    props.setSpeedDialOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={props.drawerOpen}
      onClose={cancel}
      style={{ width: '100%', zIndex: 9999 }}
    >
      <Box role="presentation" minWidth="25vw">
        <Box component="form" autoComplete="off" noValidate sx={{ p: 2 }}>
          <Typography variant="h6">Create New Workshop</Typography>
          <FormControl fullWidth sx={{ mx: 1, mt: '1em' }} variant="standard">
            <NameInput />
          </FormControl>
          <FormControl
            sx={{ mt: '2em', display: 'flex', flexDirection: 'row-reverse' }}
            variant="standard"
          >
            <Box>
              <Button variant="contained" onClick={() => saveWorkshop()}>
                Save
              </Button>
            </Box>
            <Box sx={{ mr: 1 }}>
              <Button onClick={cancel}>Cancel</Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </Drawer>
  );
};

export default WorkshopDrawer;
